import { SpecterProducts } from "@prisma/client"
import { IconCompany } from "~/components/Icons/company"
import { IconInvestor } from "~/components/Icons/investor"
import { IconPeople } from "~/components/Icons/people"

export const getProductIcon = (product: SpecterProducts) => {
  switch (product) {
    case SpecterProducts.company:
      return IconCompany
    case SpecterProducts.people:
      return IconPeople
    case SpecterProducts.investors:
      return IconInvestor
    case SpecterProducts.talent:
      return IconPeople
    case SpecterProducts.stratintel:
      return IconInvestor
    default:
      throw new Error(`No icon for product ${product}`)
  }
}

export const getProductName = (product: SpecterProducts) => {
  switch (product) {
    case SpecterProducts.talent:
      return "Talent Signals"
    case SpecterProducts.stratintel:
      return "Strategic Signals"
    case SpecterProducts.company:
      return "Companies"
    case SpecterProducts.people:
      return "People"
    case SpecterProducts.investors:
      return "Investors"
    case SpecterProducts.fundingRounds:
      return "Funding Rounds"
    case SpecterProducts.acquisition:
      return "Acquisitions"
    case SpecterProducts.ipo:
      return "IPOs"
  }
}

export const getProductShortName = (product: SpecterProducts) => {
  switch (product) {
    case SpecterProducts.talent:
      return "Talent"
    case SpecterProducts.stratintel:
      return "Strategic"
    case SpecterProducts.company:
      return "Company"
    case SpecterProducts.investors:
      return "Investors"
    case SpecterProducts.fundingRounds:
      return "Funding Rounds"
    case SpecterProducts.acquisition:
      return "Acquisitions"
    case SpecterProducts.ipo:
      return "IPOs"
    case SpecterProducts.people:
      return "People"
  }
}

export const getProductEntityName = (product: SpecterProducts) => {
  switch (product) {
    case SpecterProducts.talent:
      return "Signal"
    case SpecterProducts.stratintel:
      return "Signal"
    case SpecterProducts.company:
      return "Company"
    case SpecterProducts.investors:
      return "Investor"
    case SpecterProducts.fundingRounds:
      return "Funding Round"
    case SpecterProducts.acquisition:
      return "Acquisition"
    case SpecterProducts.ipo:
      return "IPO"
    case SpecterProducts.people:
      return "Person"
  }
}
